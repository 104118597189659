import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Homepage from './Pages/Homepage/homepage';
import ViewDetail from './Pages/View-Detail-Page/viewDetail';
import DetailPage from './Pages/Detail-Page/detailPage';
import PrivacyPolicy from './Pages/Privacy-Policy/privacy';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/main.css';
import './App.css';
import Brackets from './Pages/Brackets';
function App() {
  return (
    <div className="App">
    	<Router>
			<Routes>
				<Route exact path="/" element={<Homepage/>}/>
				<Route path="/leagues-tournaments" element={<ViewDetail/>}/>
				<Route path="/:gameType/:gameName/:id" element={<DetailPage/>}/>
				<Route path="/brackets/:id" element={<Brackets height='auto' />} />
				<Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
			</Routes>
		</Router>
    </div>
  );
}

export default App;
